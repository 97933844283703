.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0c1618;
  padding: 1rem; /* Add padding for smaller screens */
}

.login-box {
  width: 100%;
  max-width: 24rem;
  padding: 2rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border: 1px solid #004643;
  border-radius: 0.5rem;
  background-color: #faf4d3;
  color: #004643;
}

.login-header {
  text-align: center;
}

.login-header h3 {
  color: #d1ac00;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.login-form {
  margin-top: 1.25rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-group label {
  display: block;
  font-size: 0.875rem;
  color: #004643;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.form-group input {
  width: 100%; /* Change to 100% for better responsiveness */
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  color: #0c1618;
  background-color: #ffffff;
  border: 1px solid #004643;
  border-radius: 0.375rem;
  outline: none;
  transition: border-color 0.2s, background-color 0.2s;
}

.form-group input:focus {
  border-color: #d1ac00;
  background-color: #faf4d3;
}

.error-message {
  color: #d1ac00;
  font-size: 0.875rem;
  font-weight: 600;
}

.submit-button {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #004643;
  color: #faf4d3;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
  border: none;
}

.submit-button:hover {
  background-color: #0c1618;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.signup-link {
  text-align: center;
  font-size: 0.875rem;
  color: #004643;
  margin-top: 1rem;
}

.signup-link a {
  color: #d1ac00;
  font-weight: 600;
  text-decoration: none;
  transition: color 0.2s;
}

.signup-link a:hover {
  color: #0c1618;
}

.divider {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
}

.divider .line {
  flex: 1;
  height: 1px;
  background-color: #004643;
}

.divider .or {
  margin: 0 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #004643;
}

.google-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: #faf4d3;
  color: #0c1618;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid #004643;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.google-button:hover {
  background-color: #f6be9a;
}

.google-button.disabled {
  cursor: not-allowed;
  color: #9ca3af;
  border-color: #e5e7eb;
}

.google-icon {
  width: 1.25rem;
  height: 1.25rem;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .login-box {
    padding: 1.5rem;
  }

  .login-header h3 {
    font-size: 1.25rem;
  }

  .form-group input {
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  .submit-button {
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  .google-button {
    padding: 0.5rem;
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .login-box {
    padding: 1rem;
  }

  .login-header h3 {
    font-size: 1rem;
  }

  .form-group input {
    padding: 0.5rem;
    font-size: 0.75rem;
  }

  .submit-button {
    padding: 0.5rem;
    font-size: 0.75rem;
  }

  .google-button {
    padding: 0.5rem;
    font-size: 0.75rem;
  }
}
