.explore-container {
  padding: 20px;
}

.explore-container h1 {
  margin-bottom: 20px;
}

.search-bar {
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.explore-event-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.explore-event-item {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.explore-event-item:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
}

.explore-event-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.explore-event-details {
  padding: 15px;
}

.explore-event-details h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #0c1618;
}

.explore-event-details p {
  font-size: 14px;
  color: #555;
}

.explore-event-date {
  color: #888;
  font-size: 12px;
}

.explore-event-link {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 15px;
  background-color: #d1ac00;
  color: #faf4d3;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
}

.explore-event-link:hover {
  background-color: #f6be9a;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .explore-container {
    padding: 15px;
  }

  .explore-container h1 {
    font-size: 22px;
  }

  .search-bar {
    font-size: 14px;
  }

  .explore-event-list {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
  }

  .explore-event-details h3 {
    font-size: 18px;
  }

  .explore-event-details p {
    font-size: 12px;
  }

  .explore-event-link {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .explore-container {
    padding: 10px;
  }

  .explore-container h1 {
    font-size: 20px;
  }

  .search-bar {
    font-size: 12px;
  }

  .explore-event-list {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .explore-event-details h3 {
    font-size: 16px;
  }

  .explore-event-details p {
    font-size: 12px;
  }

  .explore-event-link {
    padding: 5px 10px;
    font-size: 12px;
  }
}