.select-topics-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f9;
  height: 100vh;
}

.select-topics-container h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #004643;
}

.select-topics-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topics-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.topic-item {
  padding: 10px 20px;
  background-color: #f6be9a;
  color: #0c1618;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.topic-item.selected {
  background-color: #004643;
  color: #faf4d3;
}

.submit-button {
  padding: 10px 20px;
  background-color: #004643;
  color: #faf4d3;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.submit-button:hover {
  background-color: #0c1618;
}
