.myevents-container {
  padding: 25px;
  background-color: #faf4d3;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.myevents-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.create-event-button {
  padding: 10px 18px;
  background-color: #d1ac00;
  color: #faf4d3;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;
}

.create-event-button:hover {
  background-color: #f6be9a;
}

.myevents-content {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}

.created-events,
.joined-events {
  flex: 1;
  background: #ffffff;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
}

.joined-events h2,
.created-events h2 {
  margin-bottom: 15px;
}
.event-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ececec;
}

.event-image {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 12px;
}

.event-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.manage-button,
.delete-button {
  padding: 8px 14px;
  font-size: 14px;
  background-color: #004643;
  color: #faf4d3;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  text-align: center;
  width: auto;
}

.cancel-button {
  padding: 8px 14px;
  font-size: 14px;
  background-color: #f6be9a;
  color: #004643;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  text-align: center;
  width: auto;
}

.manage-button {
  background-color: #004643;
}

.delete-button {
  background-color: #d9534f;
}

.cancel-button {
  background-color: #e69867;
}

.manage-button:hover,
.delete-button:hover,
.cancel-button:hover {
  background-color: #d1ac00;
}

@media (max-width: 768px) {
  .myevents-content {
    flex-direction: column;
  }

  .event-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .button-group {
    gap: 8px;
  }
}

@media (max-width: 480px) {
  .myevents-container {
    padding: 15px;
  }

  .event-image {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }

  .manage-button,
  .delete-button,
  .cancel-button {
    width: 100%;
    padding: 10px 18px;
  }

  .myevents-header {
    flex-direction: column;
    align-items: flex-start;
  }
}
