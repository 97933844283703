.manage-event-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.manage-event-container h1 {
  text-align: center;
  color: #004643;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #004643;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.update-button,
.manage-cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.update-button {
  background-color: #4caf50;
  color: white;
}

.manage-cancel-button {
  background-color: #f44336;
  color: white;
}

.update-button:hover {
  background-color: #45a049;
}

.manage-cancel-button:hover {
  background-color: #e53935;
}
