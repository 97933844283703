/* General Button Styles */
.edetails-button {
  display: inline-block;
  background-color: #d1ac00; /* Gold */
  color: #faf4d3; /* Light cream */
  font-weight: bold;
  border: none;
  padding: 12px 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.edetails-button:disabled {
  background-color: #a09e8f; /* Muted color for disabled state */
  cursor: not-allowed;
}

.edetails-button:hover:not(:disabled) {
  background-color: #f6be9a; /* Light coral */
  color: #004643; /* Dark green */
}

/* Specific Button Variants */
.edetails-join-button {
  margin-bottom: 10px;
  margin-right: 10px;
}

.edetails-like-button {
  margin-bottom: 10px;
  margin-right: 10px;
}

.edetails-share-button {
  margin-bottom: 10px;
  margin-right: 10px;
}

.edetails-view-my-events-button {
  margin-bottom: 20px;
}

.edetails-add-comment-button {
  margin-top: 10px;
  padding-bottom: 10px;
}

.edetails-like-comment-button {
  margin-right: 10px;
}

.edetails-delete-comment-button {
  margin-right: 10px;
}

/* Input Styling */
.edetails-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #004643; /* Dark green */
  border-radius: 8px;
  font-size: 16px;
}

/* Comments Styling */
.edetails-comment {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #004643; /* Dark green */
  border-radius: 8px;
  background-color: #faf4d3; /* Light cream */
}

.edetails-comment p {
  margin: 0;
  font-size: 14px;
  color: #0c1618; /* Very dark green */
}

/* Event Details Page */
.edetails-event-details-page {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
}

.edetails-event-details-left {
  flex: 2;
  padding: 20px;
  background-color: #faf4d3; /* Light cream */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.edetails-event-details-right {
  flex: 1;
  padding: 20px;
  background-color: #004643; /* Dark green */
  border-radius: 10px;
  color: #faf4d3; /* Light cream */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Event Image Styling */
.edetails-event-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}

/* Event Topics Styling */
.edetails-event-topics {
  margin-top: 10px;
}

.edetails-event-topic {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  background-color: #d1ac00; /* Gold */
  color: #faf4d3; /* Light cream */
  border-radius: 8px;
  font-size: 14px;
}

/* Comments Section */
.edetails-comments-section {
  margin-top: 20px;
}

.edetails-comments-section h3 {
  margin-bottom: 10px;
  color: #faf4d3; /* Light cream */
}

.edetails-comments-section ul {
  list-style-type: none;
  padding: 0;
}

.edetails-comments-section li {
  margin-bottom: 10px;
}

/* Navbar Component Placeholder */
.navbar {
  background-color: #fff;
  color: #faf4d3; 
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}