body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background-color: #faf4d3;
}

.signup-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0c1618;
  padding: 1rem; /* Add padding for smaller screens */
}

.signup-box {
  display: flex;
  width: 100%;
  max-width: 42rem;
  padding: 1.5rem;
  background-color: rgba(250, 244, 211, 0.95);
  border-radius: 1rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.signup-left {
  width: 60%;
  padding-right: 1rem;
  border-right: 1px solid rgba(0, 70, 67, 0.5);
}

.signup-header h3 {
  color: #d1ac00;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.signup-form-group {
  position: relative;
}

.signup-form-group label {
  font-size: 0.875rem;
  color: #004643;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.signup-form-group input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  color: #0c1618;
  background-color: #ffffff; /* Changed from pink to white */
  border: 1px solid #004643;
  border-radius: 0.5rem;
  outline: none;
  transition: border-color 0.2s, background-color 0.2s;
}

.signup-form-group input:focus {
  border-color: #d1ac00;
  background-color: #ffffff;
  box-shadow: 0 0 8px rgba(209, 172, 0, 0.3);
}

.signup-form-group input.invalid {
  border-color: red;
}

.signup-form-group input.valid {
  border-color: green;
}

.signup-password-group {
  display: flex;
  align-items: center;
}

.signup-show-password-button {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #d1ac00;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
}

.signup-show-password-button:hover {
  color: #0c1618;
}

.signup-password-requirements {
  margin-top: 10px;
}

.signup-password-requirements p {
  margin: 0;
  font-size: 12px;
}

.signup-password-requirements .valid {
  color: green;
}

.signup-password-requirements .invalid {
  color: red;
}

.signup-error-message {
  color: red;
  margin-bottom: 10px;
}

.signup-submit-button {
  width: 100%;
  padding: 0.6rem;
  font-size: 1rem;
  font-weight: 700;
  color: #faf4d3;
  background-color: #004643;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 0.75rem;
}

.signup-submit-button:hover {
  background-color: #0c1618;
  transform: translateY(-2px);
}

.signup-submit-button.disabled {
  background-color: #d1ac00;
  cursor: not-allowed;
}

.signup-submit-button:hover:not(.disabled) {
  background-color: #d1ac00;
}

.signup-right {
  width: 40%;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup-divider {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
}

.signup-divider .signup-line {
  flex: 1;
  height: 1px;
  background-color: #004643;
}

.signup-divider .signup-or {
  margin: 0 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #004643;
}

.signup-google-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem;
  background-color: #faf4d3;
  color: #004643;
  font-size: 0.875rem;
  font-weight: 600;
  border: 1px solid #004643;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.2s;
  margin-bottom: 50px;
}

.signup-google-button:hover {
  background-color: #f6be9a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.signup-google-button.disabled {
  background-color: #d1ac00;
  cursor: not-allowed;
}

.signup-google-button:hover:not(.disabled) {
  background-color: #357ae8;
}

.signup-google-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}

.signup-login-link {
  font-size: 0.875rem;
  color: #004643;
  text-align: center;
}

.signup-login-link a {
  color: #fff;
  text-decoration: none;
}

.signup-login-link a:hover {
  color: #0c1618;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .signup-box {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .signup-left,
  .signup-right {
    width: 100%;
    padding: 0;
    border: none;
  }

  .signup-left {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 70, 67, 0.5);
  }

  .signup-right {
    padding-top: 1rem;
  }

  .signup-header h3 {
    font-size: 1.25rem;
  }

  .signup-form-group input {
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  .signup-submit-button {
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  .signup-google-button {
    padding: 0.5rem;
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .signup-box {
    padding: 0.5rem;
  }

  .signup-header h3 {
    font-size: 1rem;
  }

  .signup-form-group input {
    padding: 0.5rem;
    font-size: 0.75rem;
  }

  .signup-submit-button {
    padding: 0.5rem;
    font-size: 0.75rem;
  }

  .signup-google-button {
    padding: 0.5rem;
    font-size: 0.75rem;
  }
}