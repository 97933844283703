.home-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  min-height: calc(100vh - 60px);
  background-color: #faf4d3;
  flex-wrap: wrap;
}

.left-section {
  flex: 1;
  max-width: 50%;
  text-align: left;
  padding-right: 20px;
  margin-top: 10%;
}

.left-section h1 {
  font-size: 2.5em;
  color: #004643;
  margin-bottom: 10px;
}

.left-section p {
  font-size: 1.2em;
  color: #0c1618;
  margin-bottom: 20px;
}

.login-link {
  padding: 10px 15px;
  background-color: #d1ac00;
  color: #faf4d3;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.login-link:hover {
  background-color: #f6be9a;
}

.right-section {
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.right-section .event-card {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  background-color: #faf4d3;
  color: #0c1618;
}

.right-section .event-card h3 {
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #004643;
}

.right-section .event-card p {
  font-size: 1em;
  color: #000;
}

.calendar-section {
  margin: 20px 0;
  width: 100%;
}

.calendar-section h2 {
  padding: 10px 0;
  margin-bottom: 20px;
  color: #004643;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__navigation {
  background-color: #0c1618; /* Change to black */
  color: #faf4d3; /* White text */
  border-radius: 8px 8px 0 0;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__navigation__label {
  font-size: 1.2em;
  color: #faf4d3; /* White text */
}

.react-calendar__tile--highlight-joined {
  background: #ff5722; /* Orange color for joined events */
  color: white;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.react-calendar__tile--highlight-created {
  background: #4caf50; /* Green color for created events */
  color: white;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.react-calendar__tile {
  color: black;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.react-calendar__tile:hover {
  background-color: #f6be9a;
  transform: scale(1.05);
}

.react-calendar__month-view__weekdays {
  font-weight: bold;
  color: #004643;
}

.react-calendar__tile--active {
  background: #d1ac00;
  color: white;
}

.legend {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  gap: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.legend-item .color-box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.legend-item .label {
  font-size: 1.1em;
  color: #004643;
}

.recommended-events h2 {
  padding: 10px 0;
  margin-bottom: 20px;
  color: #004643;
}

@media (max-width: 768px) {
  .home-main {
    flex-direction: column;
  }

  .left-section,
  .right-section {
    max-width: 100%;
    padding-right: 0;
  }

  .react-calendar {
    max-width: 95%;
  }
}