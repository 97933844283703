.elist-event-list {
    padding: 20px;
    background-color: #FAF4D3;
}

.elist-event-list h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #004643;
}

.elist-event-list-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.elist-event-item {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.elist-event-item:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
}

.elist-event-thumbnail {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.elist-event-details {
    padding: 15px;
}

.elist-event-details h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #0C1618;
}

.elist-event-details p {
    font-size: 14px;
    color: #555;
}

.elist-event-date {
    color: #888;
    font-size: 12px;
}

.elist-event-link {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 15px;
    background-color: #D1AC00;
    color: #FAF4D3;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 500;
    transition: background-color 0.2s ease-in-out;
}

.elist-event-link:hover {
    background-color: #F6BE9A;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .elist-event-list h2 {
        font-size: 20px;
    }

    .elist-event-list-container {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 15px;
    }

    .elist-event-details h3 {
        font-size: 18px;
    }

    .elist-event-details p {
        font-size: 12px;
    }

    .elist-event-link {
        padding: 6px 12px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .elist-event-list h2 {
        font-size: 18px;
    }

    .elist-event-list-container {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .elist-event-details h3 {
        font-size: 16px;
    }

    .elist-event-details p {
        font-size: 12px;
    }

    .elist-event-link {
        padding: 5px 10px;
        font-size: 12px;
    }
}