.createEvent-header {
  margin-top: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.createEvent-header h1 {
  margin: 0;
  font-size: 2rem;
  color: #004643;
}

.createEvent-header p {
  margin: 10px 0 0;
  font-size: 1rem;
  color: #0c1618;
}

.createEvent-form {
  display: flex;
  gap: 20px;
  padding: 20px;
  background-color: #faf4d3;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.createEvent-form-left,
.createEvent-form-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.createEvent-form input,
.createEvent-form textarea {
  padding: 10px;
  border: 1px solid #004643;
  border-radius: 5px;
  font-size: 1rem;
  color: #0c1618;
  background-color: #ffffff;
}

.createEvent-form input:focus,
.createEvent-form textarea:focus {
  border-color: #d1ac00;
  background-color: #ffffff;
}

.createEvent-form button {
  padding: 10px 20px;
  background-color: #004643;
  color: #faf4d3;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.createEvent-form button:hover {
  background-color: #0c1618;
}

.createEvent-form .cancel-button {
  background-color: #d9534f;
}

.createEvent-form .cancel-button:hover {
  background-color: #c9302c;
}

.createEvent-topics-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.createEvent-topic-item {
  padding: 10px 20px;
  background-color: #f6be9a;
  color: #0c1618;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.createEvent-topic-item.createEvent-selected {
  background-color: #004643;
  color: #faf4d3;
}

.createEvent-buttons {
  display: flex;
  gap: 10px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .createEvent-form {
    flex-direction: column;
  }

  .createEvent-form-left,
  .createEvent-form-right {
    width: 100%;
    padding: 0;
  }

  .createEvent-form-left {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 70, 67, 0.5);
  }

  .createEvent-form-right {
    padding-top: 1rem;
  }

  .createEvent-form input,
  .createEvent-form textarea {
    font-size: 0.875rem;
  }

  .createEvent-form button {
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  .createEvent-topic-item {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .createEvent-form {
    padding: 10px;
  }

  .createEvent-form input,
  .createEvent-form textarea {
    font-size: 0.75rem;
  }

  .createEvent-form button {
    padding: 0.5rem;
    font-size: 0.75rem;
  }

  .createEvent-topic-item {
    padding: 0.5rem;
    font-size: 0.75rem;
  }
}
