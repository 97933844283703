/* Profile Avatar Hover Effect */
.profile-avatar:hover {
  transform: scale(1.05);
  z-index: 2;
}

/* Dropdown Content */
.dropdown-content {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #ffffff;
  color: #0c1618;
  width: 180px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 10;
  opacity: 1;
  visibility: visible;
}

/* Hover Effect for Dropdown Links and Buttons */
.dropdown-content a:hover,
.dropdown-content button:hover {
  color: #faf4d3;
  background-color: #d1ac00; /* Gold background on hover */
  z-index: 11; /* Ensure the text is on top */
}

/* Ensure the text is not hidden when the box turns yellow */
.dropdown-content a,
.dropdown-content button {
  text-decoration: none;
  font-size: 1rem;
  color: #0c1618;
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 5px;
  z-index: 10;
}

/* Adjusted Profile and Logout Button Styles */
.dropdown-content .profile-button,
.dropdown-content .logout-button {
  padding: 10px 15px;
  background-color: transparent;
  color: #0c1618;
  border-radius: 5px;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  border: none;
  transition: color 0.3s ease, background-color 0.3s ease;
  z-index: 10;
}

.dropdown-content .profile-button:hover,
.dropdown-content .logout-button:hover {
  background-color: #d1ac00;
  color: #faf4d3;
  z-index: 11;
}

/* Adjusted Login Link */
.login-link {
  background-color: transparent;
  border: 1px solid #faf4d3;
  color: #faf4d3;
  text-decoration: none;
  font-weight: 500;
  padding: 8px 17px;
  border-radius: 4px;
  transition: color 0.3s ease, background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.login-link:hover {
  color: #faf4d3;
  background-color: #d1ac00;
}